<script>
import Spinner from "@/components/ui/Spinner";
import ModalPatientForm from "@/components/patient/dashboard/ModalPatientForm";
import configService from "@/services/config";
import { mapGetters } from "vuex";
import helperService from "@/services/helper";
import AlertBadge from "@/components/ui/AlertBadge";
import formService from "@/services/form";

export default {
  name: "FormTaskBox",

  props: {
    patient: {
      type: Object,
      required: true,
    },
    activeEpisode: {
      type: Object,
    },
    isCollapsable: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    AlertBadge,
    Spinner,
    ModalPatientForm,
  },

  data() {
    return {
      isLoading: false,
      forms: [],
      s3Url: configService.s3Url,
      isFormModalVisible: false,
      selectedForm: null,
      isContentHidden: this.isCollapsable,
      filterModalShowed: false,
    };
  },

  created() {
    this.getData();
  },

  computed: {
    ...mapGetters(["user", "isDoctor"]),
  },

  methods: {
    async getData() {
      this.isLoading = true;
      try {
        const forms = await formService.getPatientFormsByPatient(
          this.patient._id
        );

        this.forms = forms;
      } finally {
        this.isLoading = false;
      }
    },

    getFullName(patient) {
      return helperService.getFullName(patient);
    },

    closeFormModal() {
      this.isFormModalVisible = false;
      this.selectedForm = null;
    },

    showFormModal(form) {
      this.selectedForm = form;
      this.selectedForm.name = form.name;
      this.isFormModalVisible = true;
    },

    toggleHideContent() {
      this.isContentHidden = !this.isContentHidden;
    },
  },
};
</script>

<template lang="pug">
.box.form-box
  .header
    .expand(v-if="isCollapsable" @click='toggleHideContent()' :class="isContentHidden ? 'rotate' : ''")
      micon(name="expand_more")
    h1 Formularios
    .buttons
      .new_form.box__icon(v-if="activeEpisode && isDoctor" @click='showFormModal()')
        micon(v-show="!isContentHidden" name="add" v-tooltip="'Agregar indicacion'")
  .content(:class="(isCollapsable && isContentHidden) ? 'hide-content' : '' ")
    .no-data(v-if="!isLoading && !forms.length") No hay indicaciones

    spinner(v-if="isLoading")

    .box.clickable(
      v-else 
      v-for="form in forms", 
      :key="form._id",
      @click='showFormModal(form)'
    )
      .data
        h4.underline {{form.name}}
        h4.date {{ form.createdAt | formatDateAndTime }}
        h4 Completado por {{ getFullName(form.createdBy) }}
      
    ModalPatientForm(
      v-if="isFormModalVisible"
      :patient='patient'
      :selectedPatientForm='selectedForm'
      :activeEpisode="activeEpisode"
      @closeModal="closeFormModal"
    )
</template>

<style lang="scss">
.form-box {
  .el-timeline-item {
    &:last-child {
      padding-bottom: 0;
    }
    &__timestamp {
      margin: 0;
    }
  }
}
</style>
<style lang="scss" scoped>
.form-box {
  .header {
    position: sticky;
    top: 0;
  }

  .expand {
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    transform: rotate(180deg);
  }

  .rotate {
    transform: rotate(0deg);
  }

  .content {
    max-height: 400px;
    visibility: visible;
    transition: all 0.4s ease-in-out;

    &.hide-content {
      visibility: hidden;
      max-height: 0;
      padding-top: 0;
      padding-bottom: 0;
      transition: all 0.4s ease-in-out;
      overflow: hidden;
    }

    .box {
      flex-direction: column;
      margin: 0;
      align-items: flex-start;
      .micon {
        margin-left: 5px;
      }
    }
  }
}
</style>
